@charset "UTF-8";

/* Slider */

.slick-loading .slick-list {
    background: #fff;
    display: block;
}

/* Dots */

.slick-dotted.slick-slider {
    margin: 0;
    padding: 0;
}
.slick-dots {
    position: absolute;
    content: "";
    box-sizing: border-box;
    bottom: 0;
    padding: 0 16px;  /*padding: 0 20px;*/
    margin: 0;
    list-style: none;
    text-align: right;
    left: auto;
    right: 0;
    width: auto;
    line-height: 0;
}
@media (max-width: 1000px) {
    .slick-dots {
        bottom: 0;
    }
}
@media (max-width: 765px) {
    .slick-dots {
        text-align: center;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 0;  /*???*/
    }
}
.slick-dots li {
    position: relative;
    display: inline-block;
    content: "";
    width: 12px;
    height: 12px;
    border: 1px solid #232157;
    /*border: 1px solid white;*/
    border-radius: 50%;
    margin: 34px 7px;
    padding: 0;
    cursor: pointer;
}

/*@media (max-width: 765px) {
    .slick-dots li {
        margin: 8px;
        width: 8px;
        height: 8px;
    }
}*/
@media (max-width: 765px) {
    .slick-dots li {
        margin: 16px;
        width: 10px;
        height: 10px;
    }
}

.slick-dots li button {
    display: none;
    margin-right: 20px;
    padding: 0;
}

.slick-active {
    background-color: #232157;
}

.slick-dots li:hover {
    background-color: rgba(255,255,255,0.5);
    border-color: rgba(255,255,255,0.5);
}

.slick-dots li:active {
    background-color: #fff;
}
